// Vendors
import React from 'react';
import { Controller } from 'react-hook-form';
// Components
import { InputTextComponent } from '@openbank/cb-ui-commons';
// Types
import { AddressModalInputsTextComponentType } from './types/address-modal-inputs-text.component.type';

export const AddressModalInputsTextComponent = ({
  control,
  errorMessage,
  formatMessage,
  id,
  inputProps,
  name,
  rules,
  translations: { label, placeholder },
}: AddressModalInputsTextComponentType): React.ReactElement => (
  <Controller
    {...{ control, name, rules }}
    render={({ field }) => (
      <InputTextComponent
        {...{ ...field, ...inputProps, errorMessage }}
        isRequired={rules.required}
        label={formatMessage({ id: label })}
        placeholder={formatMessage({ id: placeholder })}
        testing={{ inputId: id }}
      />
    )}
  />
);
