// Resolvers
import { fetchCardArtsResolver } from 'providers/cards/resolvers/cards.resolvers';
// Types
import { CardArtHandlersType } from 'components/card-art/handlers/types/card-art.handlers.type';
import { CardArtReturnHandlersType } from 'components/card-art/handlers/types/card-art-return.handlers.type';

const cardImageHandler = async ({ productTypeCode, setCardImage }: CardArtHandlersType) => {
  const [response, error] = await fetchCardArtsResolver(productTypeCode);
  if (response) setCardImage(response[0]?.src);
};

const CardDetailsHandlers = (props: CardArtHandlersType): CardArtReturnHandlersType => ({
  handleCardImage: () => cardImageHandler(props),
});

export default CardDetailsHandlers;
