// Vendors
import styled from 'styled-components';

const CardStatusWrapperComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
CardStatusWrapperComponentStyled.displayName = 'CardStatusWrapperComponentStyled';

const CardStatusTopWrapperComponentStyled = styled(CardStatusWrapperComponentStyled)`
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
CardStatusWrapperComponentStyled.displayName = 'CardStatusWrapperComponentStyled';

export { CardStatusWrapperComponentStyled, CardStatusTopWrapperComponentStyled };
