// Configurations
import { getTransactionsLandingFilterModalConfiguration } from '../configurations/transactions-filter-modal-configuration/transactions-filter-modal.configuration';
// Handlers
import { FilterModalHandlers } from '../handlers/filter-modal.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';
// Types
import {
  FilterModalHookPropsType,
  FilterModalHookReturnType,
} from './types/filter-modal.hook.type';
// Utilities
import { validationForButtonDisabled } from '../utils/filter-modal.utils';
import { getFinancingLandingFilterModalConfiguration } from '../configurations/financing-filter-modal-configuration/financing-filter-modal.configuration';
import { getNotificationLandingFilterModalConfiguration } from '../configurations/notification-filter-modal-configuration/notification-filter-modal.configuration';
import { getDocumentsLandingFilterModalConfiguration } from '../configurations/documents-filter-modal-configuration/documents-filter-modal.configuration';

const FilterModalHook = ({
  filterId,
  onApplyFilter,
}: FilterModalHookPropsType): FilterModalHookReturnType => {
  const {
    copyOfFormValues,
    formValues,
    isShowFilterModal,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setIsShowFilterModal,
  } = useFiltersContextConsumerHook();

  const { formatMessage } = useCbIntl();

  const { handleFormValuesChange, handleOnClickCancel, handleApplyFilter } = FilterModalHandlers({
    copyOfFormValues,
    formValues,
    onApplyFilter,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setIsShowFilterModal,
  });

  const commonsConfigurationsProps = {
    formatMessage,
    formValues,
    handleFormValuesChange,
  };

  const filterModalContentConfiguration = {
    transactions: getTransactionsLandingFilterModalConfiguration(commonsConfigurationsProps)
      .filterModalContentConfiguration,
    financing: getFinancingLandingFilterModalConfiguration(commonsConfigurationsProps)
      .filterModalContentConfiguration,
    notification: getNotificationLandingFilterModalConfiguration(commonsConfigurationsProps)
      .filterModalContentConfiguration,
    documentation: getDocumentsLandingFilterModalConfiguration(commonsConfigurationsProps)
      .filterModalContentConfiguration,
  };

  return {
    filterModalContent: filterModalContentConfiguration[filterId],
    formatMessage,
    handleApplyFilter,
    handleOnClickCancel,
    isShowFilterModal,
    primaryButtonDisabled: validationForButtonDisabled(formValues),
  };
};

export { FilterModalHook };
