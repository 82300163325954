// Vendors
import React from 'react';
// Components
import { AddressModalInputsCityComponent } from './components/city/address-modal-inputs-city.component';
import { AddressModalInputsTextComponent } from './components/text/address-modal-inputs-text.component';
import { AddressModalInputsTextAreaComponent } from './components/text-area/address-modal-inputs-text-area.component';
import { AddressModalInputsStreetComponent } from './components/street/address-modal-inputs-street.component';
// Constants
import {
  STREET_PROPS,
  NUMBER_PROPS,
  ADDITIONAL_PROPS,
  ZIP_PROPS,
  CITY_PROPS,
} from './constants/address-modal-inputs.constants';
// Styles
import { AddressModalInputsStyled } from './address-modal-inputs.component.styled';
// Types
import { AddressModalInputsComponentType } from './types/address-modal-inputs.component.type';

export const AddressModalInputsComponent = ({
  errors: { street, number, zip, city },
  ...rest
}: AddressModalInputsComponentType): React.ReactElement => (
  <AddressModalInputsStyled>
    <AddressModalInputsStreetComponent {...{ ...STREET_PROPS, ...rest, errorMessage: street }} />
    <AddressModalInputsTextComponent {...{ ...NUMBER_PROPS, ...rest, errorMessage: number }} />
    <AddressModalInputsTextAreaComponent {...{ ...ADDITIONAL_PROPS, ...rest }} />
    <AddressModalInputsTextComponent {...{ ...ZIP_PROPS, ...rest, errorMessage: zip }} />
    <AddressModalInputsCityComponent {...{ ...CITY_PROPS, ...rest, errorMessage: city }} />
  </AddressModalInputsStyled>
);
