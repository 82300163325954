// Vendors
import React from 'react';
import { Controller } from 'react-hook-form';
// Components
import { InputTextAreaComponent } from '@openbank/cb-ui-commons';
// Types
import { AddressModalInputsTextAreaComponentType } from './types/address-modal-inputs-text-area.component.type';

export const AddressModalInputsTextAreaComponent = ({
  control,
  formatMessage,
  id,
  inputProps,
  name,
  rules,
  translations: { deleteLabel, label, placeholder },
}: AddressModalInputsTextAreaComponentType): React.ReactElement => (
  <Controller
    {...{ control, name, rules }}
    render={({ field }) => (
      <InputTextAreaComponent
        {...field}
        {...inputProps}
        isRequired={rules.required}
        fieldTestId={id}
        label={formatMessage({ id: label })}
        placeholder={formatMessage({ id: placeholder })}
        spanText={formatMessage({ id: deleteLabel })}
      />
    )}
  />
);
