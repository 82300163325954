// Vendors
import styled from 'styled-components';
// Utilities
import {
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';

const BannerComponentStyled = styled.section`
  display: flex;
  width: 100%;
  justify-content: center;

  > div {
    color: ${({ theme }) => theme.colors.fontPrimaryA};

    ${tabletMediaQuery} {
      width: initial;
    }
  }
`;
BannerComponentStyled.displayName = 'BannerComponentStyled';

export { BannerComponentStyled };
