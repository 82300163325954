// Vendors
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Enumerations
import { QuickActionsSectionEnumeration } from 'enumerations/quick-actions/quick-actions-section.enumeration';
// Contexts
import { useQuickActionsContextConsumerHook } from 'contexts/quick-actions/quick-actions.context';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Event handlers
import { QuickActionsHandlers } from '../handlers/quick-actions.handlers';
import { QuickActionsTrackingHandlers } from '../handlers/quick-actions.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { QuickActionsHookType } from './types/quick-actions.hook.type';

const useQuickActionsHook = (id: QuickActionsSectionEnumeration): QuickActionsHookType => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile } = DeviceTypeHook();
  const { quickActions: rawQuickActions } = useQuickActionsContextConsumerHook(),
    quickActions = rawQuickActions[id] || [];
  
  return {
    ...QuickActionsHandlers({
      ...AppTrackingHook(QuickActionsTrackingHandlers),
      navigate: useNavigate(),
      setIsModalOpen,
    }),
    isMobile,
    isModalOpen,
    quickActions,
  };
};

export { useQuickActionsHook };
