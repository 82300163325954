// Constants
import { VIEW_MORE_ACTION } from 'components/quick-actions/constants/quick-actions-configurations.common.constants';
// Types
import {
  QuickActionsBuilderHandlersType,
  QuickActionsBuilderReturnHandlersType,
  QuickActionsClickBuilderHandlersType,
  QuickActionsMethodBuilderHandlersType,
} from './types/quick-actions.handlers.type';

const quickActionClickHandler = ({
  handleQuickActionClickTracking,
  id,
  navigate,
  setIsModalOpen,
  tracking,
  url,
}: QuickActionsClickBuilderHandlersType): void => {
  if (id === VIEW_MORE_ACTION.id) {
    setIsModalOpen(true);
  } else {
    handleQuickActionClickTracking(tracking);
    navigate(url);
  }
};

const clickCloseModalHandler = ({ setIsModalOpen }: QuickActionsBuilderHandlersType): void => {
  setIsModalOpen(false);
};

const QuickActionsHandlers = (
  builderProps: QuickActionsBuilderHandlersType
): QuickActionsBuilderReturnHandlersType => ({
  handleQuickActionClick: (props: QuickActionsMethodBuilderHandlersType) =>
    quickActionClickHandler({ ...props, ...builderProps }),
  handleClickCloseModal: () => clickCloseModalHandler(builderProps),
});

export { QuickActionsHandlers };
