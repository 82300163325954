// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { MiscellaneousCurrenciesEnum } from '@openbank/cf-ui-static-data';
// Types
import { CreditHookType } from './types/credit.hook.type';

const CreditHook = (): CreditHookType => {
  const { formatNumber } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    {
      availableAmount = 0,
      consumedPercent = 0,
      currentAmountAuthorized = 0,
      limit = 0,
      mainCurrency = MiscellaneousCurrenciesEnum.EURO,
      totalLoanBalance = 0,
      limitIncreaseInfo = {},
    } = currentCard?.creditDetails || {};

  return {
    availableAmount,
    consumedPercent,
    currentAmountAuthorized,
    limit,
    limitIncreaseInfo,
    mainCurrency,
    totalLoanBalance,
    formatNumber,
  };
};

export default CreditHook;
