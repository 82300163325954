// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';

export default {
  APP_BANNER_DEFAULT_PROPS: {
    backgroundImage: window.ASSETS_SERVER_PATH + 'banners/banner_benefits_stars.jpg',
    title: 'Bewerte deine Amazon Visa',
    buttonConfiguration: {
      children: 'Jetzt bewerten',
      onClick: (): any =>
        window.open('https://www.amazon.de/review/create-review/?channel=AMZRevSolDE&asin=B0CN8XJB15&ie=UTF8&plattr=AVOBSRB1&ref=AVOBSRB1'),
      size: buttonConstants.SIZES.SMALL,
    },
  },
};