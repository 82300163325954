export enum ErrorTypesEnumeration {
  E001 = 'INVALID_ADDRESS',
  E002 = 'CITY_NOT_FOUND',
  E003 = 'POSTCODE_NOT_MATCH',
  FORMAT = 'FORMAT',
  MAX_LENGTH = 'MAX_LENGTH',
  REQUIRED = 'REQUIRED',
  REQUIRED_MIN_LENGTH = 'REQUIRED_MIN_LENGTH',
  UNKNOWN = 'UNKNOWN',
}
