// Types
import {
  WizardStepFooterBuilderHandlersType,
  WizardStepFooterBuilderReturnHandlersType,
} from './types/wizard-step-footer.handlers.type';

const updateRightButton =
  () =>
  (currentStep?: number): number =>
    Number(currentStep) + 1;

const updateLeftButton =
  () =>
  (currentStep?: number): number =>
    Number(currentStep) - 1 || 1;

const rightEventButtonClickHandler = ({
  handleRightEventButtonClickTracking,
  setCurrentStep,
}: Pick<
  WizardStepFooterBuilderHandlersType,
  'handleRightEventButtonClickTracking' | 'setCurrentStep'
>): void => {
  handleRightEventButtonClickTracking();
  setCurrentStep(updateRightButton());
};

const leftEventButtonClickHandler = ({
  handleLeftEventButtonClickTracking,
  setCurrentStep,
}: Pick<
  WizardStepFooterBuilderHandlersType,
  'handleLeftEventButtonClickTracking' | 'setCurrentStep'
>): void => {
  handleLeftEventButtonClickTracking();
  setCurrentStep(updateLeftButton());
};

const updateModalVisibility =
  () =>
  (prevVisibility: boolean): boolean =>
    !prevVisibility;

const toggleCancelFlowModalEventHandler = ({
  handleToggleCancelFlowModalLinkClickTracking,
  setShowCancelModal,
}: Pick<
  WizardStepFooterBuilderHandlersType,
  'handleToggleCancelFlowModalLinkClickTracking' | 'setShowCancelModal'
>): void => {
  handleToggleCancelFlowModalLinkClickTracking();
  setShowCancelModal(updateModalVisibility());
};

const WizardStepFooterHandlers = ({
  handleRightEventButtonClickTracking,
  handleLeftEventButtonClickTracking,
  handleToggleCancelFlowModalLinkClickTracking,
  setCurrentStep,
  setShowCancelModal,
}: WizardStepFooterBuilderHandlersType): WizardStepFooterBuilderReturnHandlersType => ({
  handleRightEventButtonClick: () =>
    rightEventButtonClickHandler({
      handleRightEventButtonClickTracking,
      setCurrentStep,
    }),
  handleLeftEventButtonClick: () =>
    leftEventButtonClickHandler({
      handleLeftEventButtonClickTracking,
      setCurrentStep,
    }),
  handleToggleCancelFlowModalLinkClick: () =>
    toggleCancelFlowModalEventHandler({
      handleToggleCancelFlowModalLinkClickTracking,
      setShowCancelModal,
    }),
});

export default WizardStepFooterHandlers;
