// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Handlers
import CardDetailsWrapperHandlers from 'components/card-art/handlers/card-art.handlers';
// Types
import { CardArtReturnHookType } from './types/card-art-return.hook.type';

const useCardArtHook = (): CardArtReturnHookType => {
  const [cardImage, setCardImage] = useState<string | undefined>(undefined);

  const { currentCard } = AppContextConsumerHook(),
    { productTypeCode = CharactersEnum.NO_VALUE } = currentCard || {};

  const { handleCardImage } = CardDetailsWrapperHandlers({
    productTypeCode,
    setCardImage,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleCardImage();
  }, []);

  return {
    cardImage,
  };
};

export default useCardArtHook;
