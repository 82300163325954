// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Event handlers
import { SessionExpiredHandlers } from '../handlers/session-expired.handlers';
import { SessionExpiredTrackingHandlers } from '../handlers/session-expired.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ExpiredSessionModalHookType } from './types/expired-session-modal.hook.type';

const useExpiredSessionModalHook = (): ExpiredSessionModalHookType => {
  const { isHybrid, onCancelHybridFlow } = MobileConnectorContextConsumerHook();

  return SessionExpiredHandlers({
    ...AppTrackingHook(SessionExpiredTrackingHandlers),
    isHybrid,
    onCancelHybridFlow,
  });
};

export { useExpiredSessionModalHook };
