// Constants
import { OTHER_DOCUMENTS_CODE } from '../constants/upload-document.constants';
// Resolvers
import { rejectDocumentResolver } from 'resolvers/upload-documents/upload-documents.resolvers';
// Utilities
import { isInvalidFile } from '../utils/upload-document.utils';
import {
  getFileId,
  removeFile,
  updateFileWithCurrentState,
  uploadDocumentWithUrl,
} from './utils/upload-document.handlers.utils';
// Enumerations
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';
import { DocumentsUploadErrorsEnumeration } from '../enumerations/documents-upload-errors.enumeration';
// Types
import { UploadDocumentHandlersPropsType } from './types/upload-document-props.handlers.type';
import { UploadDocumentHandlersType } from './types/upload-document.handlers.type';

const addFileHandler = async ({
  docUriController,
  customerId,
  file,
  documentType,
  id,
  onChangeFiles,
  uploadDocController,
  updateDocController,
  updateFilesOnDB,
}: Omit<UploadDocumentHandlersPropsType, 'documentId'>) => {
  try {
    onChangeFiles(
      updateFileWithCurrentState({
        file,
        fileId: id,
        state: DocumentsUploadStatesEnumeration.LOADING,
      })
    );

    const errorType = isInvalidFile(file);

    if (errorType) {
      onChangeFiles(
        updateFileWithCurrentState({
          errorType,
          fileId: id,
          state: DocumentsUploadStatesEnumeration.BACK_OFFICE_ERROR,
        })
      );
      return;
    }

    const documentId = await uploadDocumentWithUrl({
      controllers: { docUriController, uploadDocController, updateDocController },
      customerId,
      documentType,
      file,
      updateFilesOnDB,
    });

    onChangeFiles(
      updateFileWithCurrentState({
        documentId,
        fileId: id,
        state: DocumentsUploadStatesEnumeration.UPLOADED,
      })
    );
  } catch (_) {
    onChangeFiles(
      updateFileWithCurrentState({
        fileId: id,
        state: DocumentsUploadStatesEnumeration.SYSTEM_ERROR,
        errorType: DocumentsUploadErrorsEnumeration.SYSTEM_ERROR,
      })
    );
  }
};

const addAnotherFileHandler = ({
  onChangeFiles,
}: Pick<UploadDocumentHandlersPropsType, 'onChangeFiles'>) => {
  onChangeFiles(prev => [
    ...prev,
    {
      id: getFileId(prev.length),
      documentId: undefined,
      documentType: OTHER_DOCUMENTS_CODE,
      file: null,
      state: DocumentsUploadStatesEnumeration.DEFAULT,
    },
  ]);
};

const removeFileHandler = ({
  customerId,
  documentId,
  documentType,
  onChangeFiles,
  reRequestFiles,
}: Pick<
  UploadDocumentHandlersPropsType,
  'customerId' | 'documentId' | 'documentType' | 'onChangeFiles' | 'reRequestFiles'
>) => {
  rejectDocumentResolver({
    documentId,
  });
  reRequestFiles?.({ customerId, documentId, documentType });

  onChangeFiles(removeFile(documentId));
};

const onCancelUploadHandler = ({
  onChangeFiles,
  documentId,
  docUriController,
  reRequestFiles,
  uploadDocController,
  updateDocController,
}: Pick<
  UploadDocumentHandlersPropsType,
  | 'documentId'
  | 'docUriController'
  | 'onChangeFiles'
  | 'reRequestFiles'
  | 'uploadDocController'
  | 'updateDocController'
>) => {
  docUriController.abort();
  uploadDocController.abort();
  updateDocController.abort();
  onChangeFiles(removeFile(documentId));
};

export const UploadDocumentHandlers = ({
  customerId,
  documentId,
  documentType,
  docUriController,
  file,
  id,
  onChangeFiles,
  reRequestFiles,
  uploadDocController,
  updateFilesOnDB,
  updateDocController,
}: UploadDocumentHandlersPropsType): UploadDocumentHandlersType => ({
  handleAddFile: (newFile: File | null) =>
    addFileHandler({
      docUriController,
      customerId,
      file: newFile,
      documentType,
      id,
      onChangeFiles,
      uploadDocController,
      updateFilesOnDB,
      updateDocController,
    }),
  handleAddAnotherFile: () => addAnotherFileHandler({ onChangeFiles }),
  handleRemoveFile: () =>
    removeFileHandler({ customerId, documentId, documentType, onChangeFiles, reRequestFiles }),
  handleCancelUpload: () =>
    onCancelUploadHandler({
      documentId,
      docUriController,
      onChangeFiles,
      uploadDocController,
      updateDocController,
    }),
});
