// Enumerations
import { QuickActionsSectionIdsEnumeration } from 'enumerations/quick-actions/quick-actions-section-ids.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { RoutesEnumeration as RouteEnumerationCC } from 'enumerations/routes.enumeration.cc';
// Translations
import {
  ALL_OPTIONS,
  AUTOMATIC_RENEWAL,
  CARD_CONTROL,
  DEPOSIT_MONEY_EXTERNAL,
  FAST_CASH,
  PPI,
  RESET_BLOCK_PIN,
  UPDATE_PAYMENT_METHOD,
} from '../translations/quick-actions.translations.cc';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const AUTOMATIC_RENEWAL_ACTION = {
    id: QuickActionsSectionIdsEnumeration.AUTOMATIC_RENEWAL,
    component: {
      assetConfiguration: {
        id: 'security',
        testId: 'quick-action-automatic-renewal-asset',
      },
      testing: {
        buttonId: 'quick-action-automatic-renewal-button',
        labelId: 'quick-action-automatic-renewal-label',
      },
    },
    label: AUTOMATIC_RENEWAL,
    tracking: 'automaticRenewal',
    url: generateAbsoluteRoute(
      generatePathRoute([RouteEnumerationCC.CARD_SETTINGS, RouteEnumerationCC.AUTOMATIC_RENEWAL])
    ),
  },
  CARD_CONTROL_ACTION = {
    id: QuickActionsSectionIdsEnumeration.CARD_CONTROL,
    component: {
      assetConfiguration: {
        id: 'card_control',
        testId: 'quick-action-card-control-asset',
      },
      testing: {
        buttonId: 'quick-action-card-control-button',
        labelId: 'quick-action-card-control-label',
      },
    },
    label: CARD_CONTROL,
    tracking: 'cardControl',
    url: generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CARD_CONTROL])
    ),
  },
  RESET_PIN_ACTION = {
    id: QuickActionsSectionIdsEnumeration.RESET_BLOCK_PIN,
    component: {
      assetConfiguration: {
        id: 'lock',
        testId: 'quick-action-reset-pin-asset',
      },
      testing: {
        buttonId: 'quick-action-reset-pin-button',
        labelId: 'quick-action-reset-pin-label',
      },
    },
    label: RESET_BLOCK_PIN,
    tracking: 'resetPIN',
    url: generateAbsoluteRoute(
      generatePathRoute([RouteEnumerationCC.CARD_SETTINGS, RouteEnumerationCC.RESET_PIN])
    ),
  },
  VIEW_MORE_ACTION = {
    id: QuickActionsSectionIdsEnumeration.ALL_OPTIONS,
    component: {
      assetConfiguration: {
        id: 'options',
        testId: 'quick-action-view-more-asset',
      },
      testing: {
        buttonId: 'quick-action-view-more-button',
        labelId: 'quick-action-view-more-label',
      },
    },
    label: ALL_OPTIONS,
    tracking: 'viewMore',
    url: '/',
  },
  FINANCING_COMMON_QUICK_ACTIONS = [
    {
      id: QuickActionsSectionIdsEnumeration.DEPOSIT_MONEY_EXTERNAL,
      component: {
        assetConfiguration: {
          id: 'charts-slice-it',
          testId: 'quick-action-deposit-money-asset',
        },
        testing: {
          buttonId: 'quick-action-deposit-money-button',
          labelId: 'quick-action-deposit-money-label',
        },
      },
      label: DEPOSIT_MONEY_EXTERNAL,
      tracking: 'depositMoney',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.DEPOSIT_MONEY])
      ),
    },
    {
      id: QuickActionsSectionIdsEnumeration.FAST_CASH,
      component: {
        assetConfiguration: {
          id: 'payouts',
          testId: 'quick-action-fast-cash-asset',
        },
        testing: {
          buttonId: 'quick-action-fast-cash-button',
          labelId: 'quick-action-fast-cash-label',
        },
      },
      label: FAST_CASH,
      tracking: 'fastCash',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.FAST_CASH])
      ),
    },
    {
      id: QuickActionsSectionIdsEnumeration.PPI,
      component: {
        assetConfiguration: {
          id: 'card_control',
          testId: 'quick-action-ppi-asset',
        },
        testing: {
          buttonId: 'quick-action-ppi-button',
          labelId: 'quick-action-ppi-label',
        },
      },
      label: PPI,
      tracking: 'ppi',
      url: RoutesEnumeration.PPI,
    },
    {
      id: QuickActionsSectionIdsEnumeration.UPDATE_PAYMENT_METHOD,
      component: {
        assetConfiguration: {
          id: 'payment_method',
          testId: 'quick-action-update-payment-method-asset',
        },
        testing: {
          buttonId: 'quick-action-update-payment-method-button',
          labelId: 'quick-action-update-payment-method-label',
        },
      },
      label: UPDATE_PAYMENT_METHOD,
      tracking: 'paymentMethod',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.PAYMENT_METHOD])
      ),
    },
  ];

export {
  AUTOMATIC_RENEWAL_ACTION,
  CARD_CONTROL_ACTION,
  FINANCING_COMMON_QUICK_ACTIONS,
  RESET_PIN_ACTION,
  VIEW_MORE_ACTION,
};
