// Constants
import { ADDRESS_FORM_DEFAULT_VALUES } from './constants/address-modal.utils.constants';
// Enumerations
import { ErrorTypesEnumeration } from '../enums/error-types.enumeration';
// Translations
import { ERROR_MESSAGES } from '../translations/address-modal.translations';
// Types
import { FormatErrorMessagesType } from './types/format-error-messages.type';
import { FormErrorsType } from '../types/form-errors.type';
import { IsInvalidAddressType } from './types/is-invalid-address.type';
import { GetErrorMessageByIdType } from './types/get-error-message-by-id.type';
import { AddressFormValuesType } from '../types/address-form-values.type';
import { AddressType } from 'types/address.type';

const isInvalidAddress = ({ street, number, zip, city }: IsInvalidAddressType): boolean =>
  !street || !number || !zip || !city;

const getErrorMessageById = ({ formatMessage, id }: GetErrorMessageByIdType): string =>
  id && ERROR_MESSAGES[id] ? formatMessage({ id: ERROR_MESSAGES[id] }) : '';

const getCityErrorMessage = ({ errors, formatMessage }: FormatErrorMessagesType): string => {
  const id = errors?.city?.message;

  if (ErrorTypesEnumeration.E003 === id || ErrorTypesEnumeration.E001 === id) return '';

  return getErrorMessageById({ formatMessage, id });
};

const getZipErrorMessage = ({ errors, formatMessage }: FormatErrorMessagesType): string => {
  const zipError = getErrorMessageById({ formatMessage, id: errors?.zip?.message });

  if (zipError) return zipError;

  const id = errors?.city?.message;

  if (ErrorTypesEnumeration.E003 === id || ErrorTypesEnumeration.E001 === id) {
    return getErrorMessageById({ formatMessage, id });
  }

  return '';
};

const formatErrorMessages = ({
  errors,
  formatMessage,
}: FormatErrorMessagesType): FormErrorsType => ({
  city: getCityErrorMessage({ errors, formatMessage }),
  number: getErrorMessageById({ formatMessage, id: errors?.number?.message }),
  street: getErrorMessageById({ formatMessage, id: errors?.street?.message }),
  zip: getZipErrorMessage({ errors, formatMessage }),
});

const formatAddressFormValues = (address: AddressType | null): AddressFormValuesType =>
  address
    ? {
        additional: address.additionalItems1 || ADDRESS_FORM_DEFAULT_VALUES.additional,
        city: address.city,
        number: address.number,
        street: address.street,
        zip: address.postalCode,
      }
    : ADDRESS_FORM_DEFAULT_VALUES;

const trimValues = ({
  additional,
  city,
  number,
  street,
  zip,
}: AddressFormValuesType): AddressFormValuesType => ({
  additional: additional.trim(),
  city: city.trim(),
  number: number.trim(),
  street: street.trim(),
  zip: zip.trim(),
});

export { formatAddressFormValues, formatErrorMessages, isInvalidAddress, trimValues };
