// Vendors
import React from 'react';
// Components
import { CardDetailsComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_ART_PROPS } from './constants/card-art.constants';
// Hooks
import useCardArtHook from './hooks/card-art.hook';
// Types
import { CardArtComponentType } from 'components/card-art/types/card-art.component.type';

const CardArtComponent = (props: CardArtComponentType): React.ReactElement => (
  <CardDetailsComponent {...{ ...CARD_ART_PROPS, ...props, ...useCardArtHook() }} />
);

export default CardArtComponent;
