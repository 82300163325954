// Vendors
import React from 'react';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DEFAULT_FETCHING_VALUE,
  ERROR_DEFAULT_TRANSLATIONS,
  ERROR_PROPS,
  FETCH_ERROR_PROPS,
  TRY_AGAIN_BUTTON_PROPS,
} from './constants/error-wrapper.constants';
// Hooks
import useErrorWrapperHook from './hooks/error-wrapper.hook';
// Styles
import { ErrorWrapperComponentStyled } from './error-wrapper.component.styled';
// Translations
import { BUTTON } from './translations/error-wrapper.translations';
// Types
import { ErrorWrapperComponentType } from './types/error-wrapper.component.type';

const ErrorWrapperComponent = ({
  children,
  error,
  fetching = DEFAULT_FETCHING_VALUE,
}: ErrorWrapperComponentType): React.ReactElement => {
  const { formatMessage, handleTryAgainButtonClick } = useErrorWrapperHook();

  return (
    <ErrorWrapperComponentStyled {...{ error }}>
      <FetchErrorComponent
        {...{
          ...FETCH_ERROR_PROPS,
          fetching,
          error: getFetchErrorConfiguration({
            ...ERROR_PROPS,
            buttonConfiguration: {
              ...TRY_AGAIN_BUTTON_PROPS,
              children: <FormattedMessageComponent id={BUTTON} />,
              onClick: handleTryAgainButtonClick,
            },
            error,
            formatMessage,
            translations: ERROR_DEFAULT_TRANSLATIONS,
          }),
        }}
      >
        {children}
      </FetchErrorComponent>
    </ErrorWrapperComponentStyled>
  );
};

export default ErrorWrapperComponent;
