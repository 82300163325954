// Vendors
import React from 'react';
import { Controller } from 'react-hook-form';
// Components
import { InputSearchComponent } from '@openbank/cb-ui-commons';
// Styles
import { AddressModalInputsSearchComponentStyled } from './address-modal-inputs-search.component.styled';
// Types
import { AddressModalInputsSearchComponentType } from './types/address-modal-inputs-search.component.type';

export const AddressModalInputsSearchComponent = ({
  control,
  errorMessage,
  formatMessage,
  inputProps,
  name,
  rules,
  translations: { label, noResults, placeholder },
  isIosDevice,
}: AddressModalInputsSearchComponentType): React.ReactElement => (
  <AddressModalInputsSearchComponentStyled>
    <Controller
      {...{ control, name, rules }}
      render={({ field }) => (
        <InputSearchComponent
          {...{ ...field, ...inputProps, errorMessage, isIosDevice }}
          isRequired={rules.required}
          label={formatMessage({ id: label })}
          placeholder={formatMessage({ id: placeholder })}
          translations={{
            noResultsTitleText: formatMessage({ id: noResults }),
          }}
        />
      )}
    />
  </AddressModalInputsSearchComponentStyled>
);
