// Constants
import { VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME } from './constants/address-modal-inputs.utils.constants';
// Enumerations
import { ErrorTypesEnumeration } from 'components/address-modal/enums/error-types.enumeration';
import { ValidateStatusEnumeration } from 'components/address-modal/enums/validate-status.enumeration';
// Resolvers
import { validateCityWithPostcodeResolver } from 'components/address-modal/resolvers/address-modal.resolvers';
// Types
import { GetOnChangeMethodType } from './types/get-on-change-method.type';
import { AddressFormValuesType } from 'components/address-modal/types/address-form-values.type';

const debounce = (callback: (...args: any[]) => any, delay: number): ((...args: any[]) => any) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  return (...args) =>
    new Promise((resolve, reject) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        try {
          const output = callback(...args);

          resolve(output);
        } catch (err) {
          reject(err);
        }
      }, delay);
    });
};

const validateCityWithPostcode = debounce(
  async (_: string, formValues: AddressFormValuesType): Promise<boolean | string> => {
    const [response, error] = await validateCityWithPostcodeResolver({
      city: formValues.city,
      postCode: formValues.zip,
    });

    if (error) {
      return ErrorTypesEnumeration.UNKNOWN;
    }

    if (response && response.status === ValidateStatusEnumeration.FAILED && response.code) {
      return ErrorTypesEnumeration[response.code] || ErrorTypesEnumeration.UNKNOWN;
    }

    return true;
  },
  VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME
);

const isEmptyValue = (value: string): boolean | string =>
  !!value.trim() || ErrorTypesEnumeration.REQUIRED;

export { debounce, isEmptyValue, validateCityWithPostcode };
