// Vendors
import React from 'react';
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { PARAGRAPH_PROPS } from './constants/address-modal-helper-text.constants';
// Styles
import { AddressModalRequiredSymbolStyled } from './address-modal-helper-text.component.styled';
// Translations
import { DESCRIPTION } from './translations/address-modal-helper-text.translations';

export const AddressModalHelperTextComponent = (): React.ReactElement => (
  <ParagraphComponent {...PARAGRAPH_PROPS}>
    <FormattedMessageComponent
      id={DESCRIPTION}
      values={{
        asterisk: (
          <AddressModalRequiredSymbolStyled>
            {CharactersEnum.ASTERISK}
          </AddressModalRequiredSymbolStyled>
        ),
      }}
    />
  </ParagraphComponent>
);
