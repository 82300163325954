// Enumerations
import { ErrorTypesEnumeration } from 'components/address-modal/enums/error-types.enumeration';
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';
// Translations
import {
  ADDITIONAL,
  CITY,
  NUMBER,
  STREET,
  ZIP,
} from '../translations/address-modal-inputs.translations';
// Utils
import { isEmptyValue, validateCityWithPostcode } from '../utils/address-modal-inputs.utils';

const STREET_PROPS = {
  id: 'address-modal-input-street',
  inputProps: {
    disableFilter: true,
    isOptionSelectionRequired: false,
    options: [],
  },
  name: InputNamesEnumeration.STREET,
  rules: {
    required: ErrorTypesEnumeration.REQUIRED,
    validate: isEmptyValue,
  },
  translations: STREET,
};

const NUMBER_PROPS = {
  id: 'address-modal-input-number',
  inputProps: {},
  name: InputNamesEnumeration.NUMBER,
  rules: {
    required: ErrorTypesEnumeration.REQUIRED,
    validate: isEmptyValue,
  },
  translations: NUMBER,
};

const ADDITIONAL_PROPS = {
  id: 'address-modal-input-additional',
  inputProps: {
    limitCharsConfiguration: { limit: 70 },
  },
  name: InputNamesEnumeration.ADDITIONAL,
  rules: {
    required: false,
  },
  translations: ADDITIONAL,
};

const ZIP_PROPS = {
  id: 'address-modal-input-zip',
  inputProps: {},
  name: InputNamesEnumeration.ZIP,
  rules: {
    deps: [InputNamesEnumeration.CITY],
    maxLength: {
      message: ErrorTypesEnumeration.MAX_LENGTH,
      value: 5,
    },
    pattern: {
      value: /^\d+$/,
      message: ErrorTypesEnumeration.FORMAT,
    },
    required: ErrorTypesEnumeration.REQUIRED,
    validate: isEmptyValue,
  },
  translations: ZIP,
};

const CITY_PROPS = {
  id: 'address-modal-input-city',
  inputProps: {
    disableFilter: true,
  },
  name: InputNamesEnumeration.CITY,
  rules: {
    required: ErrorTypesEnumeration.REQUIRED_MIN_LENGTH,
    validate: {
      validateCityWithPostcode,
      isEmptyValue,
    },
  },
  translations: CITY,
};

const INPUT_SEARCH_PROPS = {
  isOptionSelectionRequired: false,
};

export { INPUT_SEARCH_PROPS, STREET_PROPS, NUMBER_PROPS, ADDITIONAL_PROPS, ZIP_PROPS, CITY_PROPS };
