// Vendors
import React from 'react';
// Components
import { BannerComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/banner.constants';
// Styles
import { BannerComponentStyled } from './banner.component.styled';

const AppBannerComponent = (): React.ReactElement => (
  <BannerComponentStyled>
    <BannerComponent {...constants.APP_BANNER_DEFAULT_PROPS} />
  </BannerComponentStyled>
);

export default AppBannerComponent;
