// Handlers
import { UploadDocumentHandlers } from '../handlers/upload-document.handlers';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { UseUploadDocumentHookPropsType } from './types/upload-document-props.type.hook';
import { UseUploadDocumentHookType } from './types/upload-document.hook.type';

export const useUploadDocumentHook = ({
  documentId,
  documentType,
  file,
  id,
  onChangeFiles,
  reRequestFiles,
  updateFilesOnDB,
}: UseUploadDocumentHookPropsType): UseUploadDocumentHookType => {
  const { profile } = AppContextConsumerHook(),
    { customerId } = profile?.userDetails || {};

  const docUriController = new AbortController();
  const uploadDocController = new AbortController();
  const updateDocController = new AbortController();

  return UploadDocumentHandlers({
    customerId,
    documentId,
    documentType,
    docUriController,
    file,
    id,
    onChangeFiles,
    uploadDocController,
    reRequestFiles,
    updateFilesOnDB,
    updateDocController,
  });
};
