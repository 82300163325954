// Translations
import translations from './translations/table-row-description.utils.translations';
// Types
import { TableRowDescriptionUtilsType } from './types/table-row-description.utils.type';

const getTransactionRowDescription = ({
  description,
  merchantDetails,
  formatMessage,
  poEditorNarrative,
  narrative,
}: TableRowDescriptionUtilsType): string =>
  poEditorNarrative?.key
    ? formatMessage(
        { id: poEditorNarrative.key },
        Object.fromEntries(poEditorNarrative.params.map(param => [param.key, param.value]))
      )
    : narrative ||
      description ||
      merchantDetails?.name ||
      formatMessage({ id: translations.NO_DESCRIPTION });

export { getTransactionRowDescription };
