// Constants
import { NUMBER } from 'constants/number.constants';
import { GET_CITIES_DEBOUNCED_TIME } from './constants/address-modal-inputs-city.handlers.constants';
// Resolvers
import { getCitiesByTermResolver } from 'components/address-modal/resolvers/address-modal.resolvers';
// Types
import { AddressModalInputsCityHandlersType } from './types/address-modal-inputs-city.handlers.type';
import { AddressModalInputsCityHandlersReturnType } from './types/address-modal-inputs-city-return.handlers.type';
import { CityChangeHandlerType } from './types/city-change-handler.type';
// Utils
import { debounce } from 'components/address-modal/components/inputs/utils/address-modal-inputs.utils';

const cityChangeHandler = debounce(async ({ setOptions, value }: CityChangeHandlerType) => {
  if (value.length > NUMBER.TWO) {
    const [response, _] = await getCitiesByTermResolver(value);

    setOptions(response?.cities.map(({ city }) => ({ label: city, value: city })) || []);
  } else {
    setOptions([]);
  }
}, GET_CITIES_DEBOUNCED_TIME);

const AddressModalInputsCityHandlers = ({
  setOptions,
}: AddressModalInputsCityHandlersType): AddressModalInputsCityHandlersReturnType => ({
  handleCityChange: value => cityChangeHandler({ setOptions, value }),
});

export default AddressModalInputsCityHandlers;
