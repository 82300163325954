// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TABLE_ROW_ASSET_DATE_ASSET_DESCRIPTION_PROPS,
  TABLE_ROW_ASSET_DATE_ASSET_PROPS,
} from './constants/table-row-asset-date.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import { TableRowAssetDateComponentStyled } from './table-row-asset-date.component.styled';
// Types
import { TableRowAssetDateParams } from './types/table-row-asset-date.component.type';
// Utils
import { getCategoryAsset } from 'utils/category.utils';

const TableRowAssetDateComponent = ({
  categoryCode,
  date,
}: TableRowAssetDateParams): React.ReactElement => (
  <TableRowAssetDateComponentStyled>
    <AssetComponent {...TABLE_ROW_ASSET_DATE_ASSET_PROPS} id={getCategoryAsset(categoryCode)} />
    <ParagraphSecondaryComponentStyled>
      <ParagraphComponent {...TABLE_ROW_ASSET_DATE_ASSET_DESCRIPTION_PROPS}>
        <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM} value={date} />
      </ParagraphComponent>
    </ParagraphSecondaryComponentStyled>
  </TableRowAssetDateComponentStyled>
);

export default TableRowAssetDateComponent;
