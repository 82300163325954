// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
import CardStatusButtonComponent from './components/button/card-status-button.component';
// Constants
import constants from './constants/card-status.constants';
import typesConstants from './constants/card-status-types.constants';
// Hooks
import CardStatusHook from './hooks/card-status.hook';
// Styles
import {
  CardStatusWrapperComponentStyled,
  CardStatusTopWrapperComponentStyled,
} from './card-status.component.styled';
// Translations
import translations from './translations/card-status.translations';
// Types
import { CardStatusComponentType } from './types/card-status.component.type';
// Utils
import { cardHasChangeStatus } from './utils/card-status.utils';

const CardStatusComponent = ({
  activateCardRedirection,
  breadCrumbPath,
}: CardStatusComponentType): React.ReactElement | null => {
  const { status } = CardStatusHook();

  return cardHasChangeStatus(status) ? (
    <MessageComponent
      {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_PROPS}
      type={typesConstants[status]}
    >
      <CardStatusTopWrapperComponentStyled>
        <CardStatusWrapperComponentStyled>
          <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_TITLE_PROPS}>
            <FormattedMessageComponent id={translations.TITLES[status]} />
          </ParagraphComponent>
          <ParagraphComponent
            {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_DESCRIPTION_PROPS}
          >
            <FormattedMessageComponent id={translations.DESCRIPTIONS[status]} />
          </ParagraphComponent>
        </CardStatusWrapperComponentStyled>
        <CardStatusButtonComponent {...{ activateCardRedirection, breadCrumbPath }} />
      </CardStatusTopWrapperComponentStyled>
    </MessageComponent>
  ) : null;
};

export default CardStatusComponent;
