// Vendors
import React from 'react';
import { isEmpty } from '@openbank/web-ui-framework';
// Components
import { ModalAdressComponent } from '@openbank/cb-ui-commons';
import { AddressModalHelperTextComponent } from './components/helper-text/address-modal-helper-text.component';
import { AddressModalInputsComponent } from './components/inputs/address-modal-inputs.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MODAL_LINK_PROPS, MODAL_PROPS } from './constants/address-modal.constants';
// Hooks
import { useAddressModalHook } from './hooks/address-modal.hook';
// Styles
import { AddressModalContentStyled } from './address-modal.component.styled';
// Translations
import {
  ADDRESS_MODAL_CANCEL_BUTTON,
  ADDRESS_MODAL_SAVE_BUTTON,
  ADDRESS_MODAL_TITLE,
} from './translations/address-modal.translations';
// Types
import { AddressModalComponentType } from './types/address-modal.component.type';
// Utils
import { formatErrorMessages } from './utils/address-modal.utils';

export const AddressModalComponent = ({
  defaultValues,
  isOpen,
  onCancel,
  onClose,
  onError,
  onSave,
}: AddressModalComponentType): React.ReactElement => {
  const {
    errors,
    formatMessage,
    handleReset,
    handleSave,
    handleSubmit,
    isIosDevice,
    isValidating,
    showModal,
    ...rest
  } = useAddressModalHook({
    defaultValues,
    onError,
    onSave,
  });

  return (
    <ModalAdressComponent
      {...{ ...MODAL_PROPS, isIosDevice }}
      footerConfiguration={{
        buttonConfiguration: {
          children: <FormattedMessageComponent id={ADDRESS_MODAL_SAVE_BUTTON} />,
          disabled: !isEmpty(errors) || isValidating,
          onClick: handleSubmit(handleSave),
          testId: 'address-modal-save-button',
        },
        linkConfiguration: {
          ...MODAL_LINK_PROPS,
          children: <FormattedMessageComponent id={ADDRESS_MODAL_CANCEL_BUTTON} />,
          onClick: handleReset(onCancel),
          testId: 'address-modal-cancel-button',
        },
      }}
      isOpen={isOpen && !showModal}
      title={<FormattedMessageComponent id={ADDRESS_MODAL_TITLE} />}
      onClose={handleReset(onClose)}
    >
      <AddressModalContentStyled>
        <AddressModalHelperTextComponent />
        <AddressModalInputsComponent
          {...{
            ...rest,
            isIosDevice,
            errors: formatErrorMessages({ errors, formatMessage }),
            formatMessage,
          }}
        />
      </AddressModalContentStyled>
    </ModalAdressComponent>
  );
};
