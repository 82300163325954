// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const AddressModalContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingS};
    margin-bottom: ${({ theme }) => theme.spaces.spacingXxxxs};
  }
`;
