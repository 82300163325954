// Vendors
import React from 'react';
// Components
import { AddressModalInputsSearchComponent } from '../search/address-modal-inputs-search.component';
import { AddressModalInputsStreetComponentType } from './types/address-modal-inputs-street.component.type';
// Hooks
import { useAddressModalInputsStreetHook } from './hooks/address-modal-inputs-street.hook';

export const AddressModalInputsStreetComponent = ({
  inputProps,
  control,
  setValue,
  ...rest
}: AddressModalInputsStreetComponentType): React.ReactElement => {
  const streetProps = useAddressModalInputsStreetHook({ control, setValue });

  return (
    <AddressModalInputsSearchComponent
      {...{ ...rest, control, inputProps: { ...inputProps, ...streetProps }, setValue }}
    />
  );
};
