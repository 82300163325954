// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import WizardFeedbackErrorHandlers from '../handlers/wizard-feedback-error.handlers';
import WizardFeedbackErrorTrackingHandlers from '../handlers/wizard-feedback-error.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RecoveryKeyFeedbackBuilderReturnHandlersType } from '../handlers/types/wizard-feedback-error-builder-return.handlers.type';
import { WizardFeedbackResultComponentType } from 'components/wizard-feedback/types/wizard-feedback-result.component.type';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const useWizardFeedbackErrorHook = (
  props: Pick<WizardFeedbackResultComponentType, 'exitRoute' | 'buttonClick' | 'linkClick'>
): RecoveryKeyFeedbackBuilderReturnHandlersType => {
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const { onCancelHybridFlow } = MobileConnectorContextConsumerHook();

  return WizardFeedbackErrorHandlers({
    ...AppTrackingHook(WizardFeedbackErrorTrackingHandlers),
    ...props,
    navigate: useNavigate(),
    onCancelHybridFlow,
    setResult,
  });
};

export default useWizardFeedbackErrorHook;
