// Handlers
import { WizardStepFooterCancelModalCommonHandlers } from './wizard-step-footer-cancel-modal.common.handlers';
// Types
import { WizardStepFooterCancelModalBuilderReturnHandlersType } from './types/wizard-step-footer-cancel-modal-builder-return.handlers.type';
import { WizardStepFooterCancelModalBuilderHandlersType } from './types/wizard-step-footer-cancel-modal-builder.handlers.type';
import { WizardStepFooterCancelModalRecoveryHandlersType } from './types/wizard-step-footer-cancel-modal-recovery.handlers.type';
// Utilities
import { getWizardStepFooterCancelUrl } from 'components/wizard-step-footer/components/cancel-modal/handlers/utils/wizard-step-footer.handlers.utils';

const cancelExitProcessLinkClickHandler = async ({
  cancelUrl,
  onClose,
  handleCancelExitProcessLinkClickTracking,
  onCancelHybridFlow,
  cancelModalLinkClick,
}: WizardStepFooterCancelModalRecoveryHandlersType): Promise<void> => {
  handleCancelExitProcessLinkClickTracking();

  let hasToNavigate = false;
  if (cancelModalLinkClick) {
    const response = await cancelModalLinkClick?.();
    hasToNavigate = response?.hasToNavigate ?? false;
  }
  if (hasToNavigate) {
    onCancelHybridFlow(getWizardStepFooterCancelUrl(cancelUrl));
  } else {
    onClose();
  }
};

const confirmToContinueProcessButtonClickHandler = ({
  cancelUrl,
  cancelModalButtonClick,
  handleConfirmToContinueProcessButtonClickTracking,
  onCancelHybridFlow,
}: Pick<
  WizardStepFooterCancelModalBuilderHandlersType,
  | 'cancelModalButtonClick'
  | 'handleConfirmToContinueProcessButtonClickTracking'
  | 'cancelUrl'
  | 'onCancelHybridFlow'
>): void => {
  handleConfirmToContinueProcessButtonClickTracking();
  cancelModalButtonClick
    ? cancelModalButtonClick()
    : onCancelHybridFlow(getWizardStepFooterCancelUrl(cancelUrl));
};

const WizardStepFooterCancelModalHandlers = (
  props: WizardStepFooterCancelModalBuilderHandlersType
): WizardStepFooterCancelModalBuilderReturnHandlersType => ({
  ...WizardStepFooterCancelModalCommonHandlers(props),
  handleCancelExitProcessLinkClick: () => cancelExitProcessLinkClickHandler(props),
  handleConfirmToContinueProcessButtonClick: () =>
    confirmToContinueProcessButtonClickHandler(props),
});

export default WizardStepFooterCancelModalHandlers;
