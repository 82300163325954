// Vendors
import React from 'react';
// Components
import { AddressModalInputsSearchComponent } from '../search/address-modal-inputs-search.component';
// Hooks
import AddressModalInputsCityHook from './hooks/address-modal-inputs-city.hook';
// Types
import { AddressModalInputsCityComponentType } from './types/address-modal-inputs-city.component.type';

export const AddressModalInputsCityComponent = ({
  inputProps,
  control,
  ...rest
}: AddressModalInputsCityComponentType): React.ReactElement => {
  const cityInputProps = AddressModalInputsCityHook(control);

  return (
    <AddressModalInputsSearchComponent
      {...{ ...rest, control, inputProps: { ...cityInputProps, ...inputProps } }}
    />
  );
};
