const DOMAIN_INPUTS = 'cobranded.services.userSettings.privateArea.cbModalAddress.inputs.';

const ADDITIONAL = {
  label: `${DOMAIN_INPUTS}additional.label`,
  placeholder: `${DOMAIN_INPUTS}additional.placeholder`,
  deleteLabel: `${DOMAIN_INPUTS}additional.delete`,
};
const CITY = {
  label: `${DOMAIN_INPUTS}city.label`,
  placeholder: `${DOMAIN_INPUTS}city.placeholder`,
  noResults: `${DOMAIN_INPUTS}noResults`,
};
const NUMBER = {
  label: `${DOMAIN_INPUTS}number.label`,
  placeholder: `${DOMAIN_INPUTS}number.placeholder`,
};
const STREET = {
  label: `${DOMAIN_INPUTS}street.label`,
  placeholder: `${DOMAIN_INPUTS}street.placeholder`,
  noResults: `${DOMAIN_INPUTS}noResults`,
};
const ZIP = {
  label: `${DOMAIN_INPUTS}zip.label`,
  placeholder: `${DOMAIN_INPUTS}zip.placeholder`,
};

export { ADDITIONAL, CITY, NUMBER, STREET, ZIP };
