// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { WizardStepFooterTrackingHandlersType } from 'components/wizard-step-footer/handlers/types/wizard-step-footer.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const recoveryKeyStep1EmailErrorInputEventTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'newModifyEmail',
    eventLabel: 'newModifyEmail',
    errorCategory: 'invalidEmail',
    errorDescription: 'invalidEmail',
  },
  type: TrackingEventsEnum.LINK,
});

const rightEventButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'next',
  },
  type: TrackingEventsEnum.LINK,
});

const leftEventButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'previous',
  },
  type: TrackingEventsEnum.LINK,
});

const toggleCancelFlowModalLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancel',
  },
  type: TrackingEventsEnum.LINK,
});

const WizardStepFooterTrackingHandlers = (
  track: TrackBuilderType
): WizardStepFooterTrackingHandlersType => ({
  handleRightEventButtonClickTracking: () => track(rightEventButtonClickTrackingHandler()),
  handleLeftEventButtonClickTracking: () => track(leftEventButtonClickTrackingHandler()),
  handleToggleCancelFlowModalLinkClickTracking: () =>
    track(toggleCancelFlowModalLinkClickTrackingHandler()),
});

export default WizardStepFooterTrackingHandlers;
