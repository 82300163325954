// Vendors
import React from 'react';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Components
import { FilterModalListSelectorContentComponent } from '../components/list-selector-content/filter-modal-list-selector-content.component';
// Constants
import { DATE_OPTION_RANGE_TITLE } from '../translations/filter-modal-list-selector-date.translations';
import { DAY_OF_MONTH, TIME_PERIODS } from '../constants/filter-modal-list-selector-date.constants';
// Enumerations
import { CalculatedDateOptionsEnumeration } from '../enumerations/calculated-dates-options-types.enumeration';
// Types
import {
  CalculatedDateReturnType,
  CalculatedDatesPropsType,
  GetFirstAndLastDayReturnType,
  MapDateOptionsPropsType,
  ProcessOptionsListReturnType,
} from './types/filter-modal-list-selector-date.utils.type';

const calculatedDates = ({
  calculatedDatesOptions,
  format,
  formatDate,
}: CalculatedDatesPropsType): CalculatedDateReturnType => {
  if (
    calculatedDatesOptions === CalculatedDateOptionsEnumeration.ALL ||
    calculatedDatesOptions === CalculatedDateOptionsEnumeration.CUSTOM
  ) {
    return {
      firstDay: undefined,
      lastDay: undefined,
    };
  }

  const calculatedDateOptions = {
    [CalculatedDateOptionsEnumeration.LAST_MONTH]: getFirstAndLastDayOfLastMonth(),
    [CalculatedDateOptionsEnumeration.LAST_SIX_MONTHS]: getFirstAndLastDayOfLastSixMonths(),
    [CalculatedDateOptionsEnumeration.LAST_FIFTEEN_DAYS]: getLastFifteenDays(),
    [CalculatedDateOptionsEnumeration.LAST_TWELVE_MONTHS]: getFirstAndLastDayOfLastTwelveMonths(),
  };

  const firstDay = (calculatedDateOptions[calculatedDatesOptions] as GetFirstAndLastDayReturnType)
    ?.firstDay;
  const lastDay = (calculatedDateOptions[calculatedDatesOptions] as GetFirstAndLastDayReturnType)
    ?.lastDay;

  const formattedFirstDay =
    typeof firstDay === 'string' ? firstDay : firstDay?.toISOString().split('T')[0];
  const formattedLastDay =
    typeof lastDay === 'string' ? lastDay : lastDay?.toISOString().split('T')[0];

  if (format) {
    return {
      firstDay: formatDate?.(formattedFirstDay, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY),
      lastDay: formatDate?.(formattedLastDay, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY),
    };
  }
  return {
    firstDay: formattedFirstDay,
    lastDay: formattedLastDay,
  };
};

const getFirstAndLastDayOfLastMonth = (): GetFirstAndLastDayReturnType => {
  const date = new Date();
  date.setMonth(date.getMonth() - TIME_PERIODS.ONE_MONTH_AGO);

  const firstDay = new Date(date.getFullYear(), date.getMonth(), DAY_OF_MONTH.FIRST);
  const lastDay = new Date();

  return {
    firstDay,
    lastDay,
  };
};

const getFirstAndLastDayOfLastSixMonths = (): GetFirstAndLastDayReturnType => {
  const lastDay = new Date();
  const firstDay = new Date();
  firstDay.setMonth(firstDay.getMonth() - TIME_PERIODS.SIX_MONTHS_AGO);

  return {
    firstDay,
    lastDay,
  };
};

const getLastFifteenDays = (): GetFirstAndLastDayReturnType => {
  const lastDay = new Date();
  const firstDay = new Date();
  firstDay.setDate(firstDay.getDate() - TIME_PERIODS.FIFTEEN_DAYS_AGO);

  return {
    firstDay,
    lastDay,
  };
};

const getFirstAndLastDayOfLastTwelveMonths = (): GetFirstAndLastDayReturnType => {
  const lastDay = new Date();
  const firstDay = new Date();
  firstDay.setMonth(firstDay.getMonth() - TIME_PERIODS.TWELVE_MONTHS_AGO);

  return {
    firstDay,
    lastDay,
  };
};

const mapDateOptions = ({
  options,
  formatMessage,
  formatDate,
}: MapDateOptionsPropsType): ProcessOptionsListReturnType[] =>
  options.map(({ id, helperMessageId, calculatedDatesOptions }) => ({
    id,
    helperMessage: calculatedDatesOptions ? (
      <FilterModalListSelectorContentComponent
        titleOption={formatMessage({ id: helperMessageId })}
        dateDescription={formatMessage(
          { id: DATE_OPTION_RANGE_TITLE },
          calculatedDates({ calculatedDatesOptions, format: true, formatDate })
        )}
      />
    ) : (
      formatMessage({ id: helperMessageId })
    ),
  }));

const isCustomDateOption = (dateOptionValue: string | undefined): boolean => {
  return dateOptionValue === 'custom';
};

export {
  calculatedDates,
  getFirstAndLastDayOfLastMonth,
  getFirstAndLastDayOfLastSixMonths,
  getFirstAndLastDayOfLastTwelveMonths,
  getLastFifteenDays,
  isCustomDateOption,
  mapDateOptions,
};
