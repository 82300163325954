// Vendors
import React from 'react';
// Components
import BlockCardAddressContentComponent from '../address-content/block-card-address-content.component';
import { AddressModalComponent } from 'components/address-modal/address-modal.component';
import { BlockCardAddressDeleteModalComponent } from './components/delete-modal/block-card-address-delete-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { InputSelectorBoxAddressComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getInputSelectorBoxAddressConfiguration } from './configurations/block-card-address.component.configurations';
// Constants
import { DEFAULT_REPORT_VALUE } from './hooks/constants/block-card-address.hook.constants';
// Hooks
import BlockCardAddressHook from './hooks/block-card-address.hook';
// Styles
import {
  BlockCardAddressComponentStyled,
  InputSelectorBoxAddressesStyled,
} from './block-card-address.component.styled';
// Translations
import { LOCATION, LOCATION_DESCRIPTION } from './translations/block-card-address.translations';
// Types
import { BlockCardAddressComponentPropsType } from './types/block-card-address.component.type';

const BlockCardAddressComponent = ({
  reportFraudulentMovement = DEFAULT_REPORT_VALUE,
}: BlockCardAddressComponentPropsType): React.ReactElement => {
  const {
    addressCardActions,
    addresses,
    addressModalActions,
    deleteModalActions,
    formatMessage,
    selectedAddress,
    showAddressModal,
    showDeleteModal,
    tempAddress,
  } = BlockCardAddressHook();

  return (
    <BlockCardAddressComponentStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={LOCATION} bold />
      </ParagraphComponent>
      <ParagraphComponent>
        <FormattedMessageComponent id={LOCATION_DESCRIPTION} />
      </ParagraphComponent>

      <InputSelectorBoxAddressesStyled isIosDevice={false}>
        {addresses.map(({ address, type }) => (
          <InputSelectorBoxAddressComponent
            key={type}
            {...getInputSelectorBoxAddressConfiguration({
              ...addressCardActions,
              address,
              formatMessage,
              selectedAddress,
              type,
              isReportFraudulentMovement: reportFraudulentMovement,
            })}
          >
            <BlockCardAddressContentComponent address={address} />
          </InputSelectorBoxAddressComponent>
        ))}
      </InputSelectorBoxAddressesStyled>

      <AddressModalComponent
        {...addressModalActions}
        defaultValues={tempAddress}
        isOpen={showAddressModal}
      />
      <BlockCardAddressDeleteModalComponent isOpen={showDeleteModal} actions={deleteModalActions} />
    </BlockCardAddressComponentStyled>
  );
};

export { BlockCardAddressComponent };
