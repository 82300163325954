// Enumerations
import { ErrorTypesEnumeration } from '../enums/error-types.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cbModalAddress.',
  DOMAIN_BUTTONS = `${DOMAIN}buttons.`;

const ADDRESS_MODAL_TITLE = `${DOMAIN}title`;

const ADDRESS_MODAL_CANCEL_BUTTON = `${DOMAIN_BUTTONS}cancel`;

const ADDRESS_MODAL_SAVE_BUTTON = `${DOMAIN_BUTTONS}save`;

const ERROR_MESSAGES = {
  [ErrorTypesEnumeration.E001]: `${DOMAIN}errorMessages.incorrectField`,
  [ErrorTypesEnumeration.E002]: `${DOMAIN}errorMessages.incorrectField`,
  [ErrorTypesEnumeration.E003]: `${DOMAIN}errorMessages.incorrectField`,
  [ErrorTypesEnumeration.FORMAT]: `${DOMAIN}errorMessages.format`,
  [ErrorTypesEnumeration.MAX_LENGTH]: `${DOMAIN}errorMessages.maxLength`,
  [ErrorTypesEnumeration.REQUIRED]: `${DOMAIN}errorMessages.required`,
  [ErrorTypesEnumeration.REQUIRED_MIN_LENGTH]: `${DOMAIN}errorMessages.requiredMinLength`,
};

export {
  ADDRESS_MODAL_CANCEL_BUTTON,
  ADDRESS_MODAL_SAVE_BUTTON,
  ADDRESS_MODAL_TITLE,
  ERROR_MESSAGES,
};
