// Enumerations
import { BlockReasonsEnumeration } from '../../enumerations/block-reasons.enumeration';
// Translations
import { LOST, STOLEN } from '../translations/block-card-reason.translations';

export default {
  BLOCK_REASONS: [
    {
      eventLabel: 'yesLostCardRadioButton',
      id: LOST,
      testId: 'block-card-reason-lost',
      value: BlockReasonsEnumeration.LOST,
    },
    {
      eventLabel: 'yesStolenCardRadioButton',
      id: STOLEN,
      testId: 'block-card-reason-stolen',
      value: BlockReasonsEnumeration.STOLEN,
    },
  ],
};
