// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import LabelsLinkCardChangeComponent from './components/change/labels-link-card-change.component';
import LabelsLinkCardLinkComponent from './components/link/labels-link-card-link.component';
import LabelsLinkCardTitleComponent from './components/title/labels-link-card-title.component';
// Constants
import constants from './constants/labels-link-card.constants';
// Hooks
import LabelsLinkCardHook from './hooks/labels-link-card.hook';
// Styles
import { AppCardSectionComponentStyled } from 'styles/app.styled';
import {
  LabelsLinkCardComponentStyled,
  LabelsLinkCardContentComponentStyled,
} from './labels-link-card.component.styled';
// Types
import { LabelsLinkCardComponentType } from './types/labels-link-card.component.type';

const LabelsLinkCardComponent = ({
  changeConfiguration,
  children,
  description,
  link,
  onClick,
  testing: { descriptionId, linkId, titleId } = constants.DEFAULT_LABELS_LINK_CARD_TESTING_PROPS,
  title,
  url,
  useTitleHeader = constants.LABELS_LINK_CARD_TITLE_DEFAULT_PROPS.useTitleHeader,
}: LabelsLinkCardComponentType): React.ReactElement<LabelsLinkCardComponentType> => {
  const { handleLabelsLinkCardLinkClick } = LabelsLinkCardHook({ url, onClick });

  return (
    <AppCardSectionComponentStyled>
      <LabelsLinkCardComponentStyled {...{ useTitleHeader }}>
        <LabelsLinkCardTitleComponent {...{ useTitleHeader, title, titleId }} />
        <LabelsLinkCardContentComponentStyled>
          <ParagraphComponent testId={descriptionId}>
            <FormattedMessageComponent id={description} values={{ cardName: 'Amazon Visa' }} />
          </ParagraphComponent>
          {children}
          <LabelsLinkCardLinkComponent {...{ handleLabelsLinkCardLinkClick, link, linkId }} />
          <LabelsLinkCardChangeComponent {...{ changeConfiguration }} />
        </LabelsLinkCardContentComponentStyled>
      </LabelsLinkCardComponentStyled>
    </AppCardSectionComponentStyled>
  );
};

export default LabelsLinkCardComponent;
