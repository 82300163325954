// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Constants
import {
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_01_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_02_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_BUTTON_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_LINK_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_ASSET_PROPS,
} from 'components/wizard-step-footer/components/cancel-modal/constants/wizard-step-footer-cancel-modal.constants';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Hooks
import useWizardStepFooterCancelModalHook from 'components/wizard-step-footer/components/cancel-modal/hooks/wizard-step-footer-cancel-modal.hook';
// Styles
import {
  WizardStepFooterFeedbackCancelModalAssetComponentStyled,
  WizardStepFooterCancelModalComponentStyled,
} from './wizard-step-footer-cancel-modal.component.styled';
// Translations
import * as translations from 'components/wizard-step-footer/components/cancel-modal/translations/wizard-step-footer-cancel-modal.translations';
// Types
import { WizardStepFooterCancelModalComponentType } from 'components/wizard-step-footer/components/cancel-modal/types/wizard-step-footer-cancel-modal.component.type';

const WizardStepFooterCancelModalComponent = ({
  showModal,
  cancelModalTranslations,
  disabledButtonsCancelModal,
  ...rest
}: WizardStepFooterCancelModalComponentType): React.ReactElement | null => {
  const {
    handleConfirmToContinueProcessButtonClick,
    handleCloseWizardStepFooterCancelProcessModalAssetClick,
    handleCancelExitProcessLinkClick,
  } = useWizardStepFooterCancelModalHook({ ...rest, showModal });

  const {
    BUTTON = translations.BUTTON,
    DESCRIPTION_01 = translations.DESCRIPTION_01,
    VALUESDESCRIPTION_01,
    DESCRIPTION_02,
    LINK = translations.LINK,
    TITLE = translations.TITLE,
  } = cancelModalTranslations || {};

  return showModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...WIZARD_STEP_FOOTER_CANCEL_MODAL_LINK_PROPS,
        disabled: disabledButtonsCancelModal,
        children: <FormattedMessageComponent id={LINK} />,
        onClick: handleCancelExitProcessLinkClick,
      }}
      primaryButtonConfiguration={{
        ...WIZARD_STEP_FOOTER_CANCEL_MODAL_BUTTON_PROPS,
        disabled: disabledButtonsCancelModal,
        children: <FormattedMessageComponent id={BUTTON} />,
        onClick: handleConfirmToContinueProcessButtonClick,
      }}
      onHeaderClose={
        disabledButtonsCancelModal
          ? () => undefined
          : handleCloseWizardStepFooterCancelProcessModalAssetClick
      }
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <WizardStepFooterCancelModalComponentStyled>
        <WizardStepFooterFeedbackCancelModalAssetComponentStyled>
          <AssetComponent {...WIZARD_STEP_FOOTER_CANCEL_MODAL_ASSET_PROPS} />
        </WizardStepFooterFeedbackCancelModalAssetComponentStyled>
        <ParagraphComponent {...WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_01_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION_01} values={VALUESDESCRIPTION_01} />
        </ParagraphComponent>
        {DESCRIPTION_02 && (
          <ParagraphComponent {...WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_02_PROPS}>
            <FormattedMessageComponent id={DESCRIPTION_02} />
          </ParagraphComponent>
        )}
      </WizardStepFooterCancelModalComponentStyled>
    </ModalComponent>
  ) : null;
};

export default WizardStepFooterCancelModalComponent;
