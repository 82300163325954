// Vendors
import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
// Handlers
import AddressModalInputsCityHandlers from '../handlers/address-modal-inputs-city.handlers';
// Types
import { AddressModalInputsCityReturnHookType } from './types/address-modal-inputs-city-return.hook.type';
import { InputSearchOptionType } from 'components/address-modal/components/inputs/types/input-search-option.type';
import { AddressFormValuesType } from 'components/address-modal/types/address-form-values.type';

const AddressModalInputsCityHook = (
  control: Control<AddressFormValuesType, any>
): AddressModalInputsCityReturnHookType => {
  const [options, setOptions] = useState<InputSearchOptionType[]>([]);

  const { city = '' } = useWatch({ control });

  const { handleCityChange } = AddressModalInputsCityHandlers({
    setOptions,
  });

  useEffect(() => {
    handleCityChange(city);
  }, [city]);

  return {
    options,
  };
};

export default AddressModalInputsCityHook;
