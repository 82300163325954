// Types
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { WizardFeedbackSuccessLinkClickHandlerType } from './types/wizard-feedback-success-link-click-handler.type';
import {
  RecoveryKeyFeedbackBuilderReturnHandlersType,
  RecoveryKeyFeedbackBuilderHandlersType,
} from './types/wizard-feedback-success.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const wizardFeedbackSuccessLinkClickHandler = ({
  exitRoute,
  handleWizardFeedbackSuccessLinkClickTracking,
  linkClick,
  navigate,
  onSuccessHybridFlow,
}: WizardFeedbackSuccessLinkClickHandlerType): void => {
  handleWizardFeedbackSuccessLinkClickTracking();
  linkClick
    ? linkClick()
    : onSuccessHybridFlow?.({
        navigate,
        url: generateAbsoluteRoute(exitRoute),
      });
};
const WizardFeedbackSuccessHandlers = ({
  exitRoute,
  handleWizardFeedbackSuccessLinkClickTracking,
  linkClick,
  navigate,
  onSuccessHybridFlow,
}: RecoveryKeyFeedbackBuilderHandlersType): RecoveryKeyFeedbackBuilderReturnHandlersType => ({
  handleWizardFeedbackSuccessLinkClick: () =>
    wizardFeedbackSuccessLinkClickHandler({
      exitRoute,
      handleWizardFeedbackSuccessLinkClickTracking,
      linkClick,
      navigate,
      onSuccessHybridFlow,
    }),
});

export default WizardFeedbackSuccessHandlers;
